var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"full-height"},[_c('v-row',{staticStyle:{"height":"90%"},attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-card',{staticClass:"pa-6 my-4",attrs:{"width":"100%","raised":"","height":"100%"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.onClickPrevBtn()}}},[_vm._v("arrow_back")]),_c('div',{staticClass:"pl-2 text-h5 primary--text font-weight-medium"},[_vm._v(" Additional Details ")])],1),_c('v-form',[_c('ValidationObserver',{ref:"dynamicFieldValuesForm"},[_c('v-row',{staticClass:"ma-4"},_vm._l((_vm.selectedItem),function(field){return _c('v-col',{key:field.Custom_Field_Id,attrs:{"cols":"12","md":"4","sm":"6"}},[(
                      field.Custom_Field_Type.toLowerCase() ===
                        'single choice' ||
                      field.Custom_Field_Type.toLowerCase() ===
                        'multiple choice'
                    )?_c('ValidationProvider',{ref:`Custom Select` + field.Custom_Field_Id,refInFor:true,attrs:{"name":field.Custom_Field_Name,"rules":field.Mandatory === 'Yes' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-autocomplete',{ref:`Custom Select` + field.Custom_Field_Id,refInFor:true,attrs:{"outlined":"","items":_vm.parseDropdownItems(field.Dropdown_Values),"multiple":field.Custom_Field_Type.toLowerCase() ===
                        'multiple choice',"clearable":"","error-messages":errors},on:{"input":function($event){return _vm.onChangeFields($event, field.Value)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(field.Custom_Field_Name)+" "),(field.Mandatory === 'Yes')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(field.Value),callback:function ($$v) {_vm.$set(field, "Value", $$v)},expression:"field.Value"}})]}}],null,true)}):(
                      field.Custom_Field_Type.toLowerCase() === 'url'
                    )?_c('v-btn',{staticClass:"text-subtitle-1 font-weight-regular text-decoration-underline ml-n4",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.urlRedirection(field?.Url_Link)}}},[_vm._v(" "+_vm._s(field.Custom_Field_Name ? field.Custom_Field_Name : "")+" ")]):(
                      field.Custom_Field_Type.toLowerCase() === 'number'
                    )?_c('ValidationProvider',{ref:`${field.Custom_Field_Id}`,refInFor:true,attrs:{"name":field.Custom_Field_Name,"rules":field.Mandatory.toLowerCase() === 'yes'
                        ? `required|numeric|minimum:${field.Min_Validation}|maximum:${field.Max_Validation}`
                        : `numeric|minimum:${field.Min_Validation}|maximum:${field.Max_Validation}`,},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","type":"number","clearable":"","error-messages":errors},on:{"input":function($event){return _vm.onChangeFields($event, field.Value)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(field.Custom_Field_Name)+" "),(field.Mandatory.toLowerCase() === 'yes')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(field.Value),callback:function ($$v) {_vm.$set(field, "Value", $$v)},expression:"field.Value"}})]}}],null,true)}):(
                      field.Custom_Field_Type.toLowerCase() === 'text area'
                    )?_c('ValidationProvider',{ref:`${field.Custom_Field_Id}`,refInFor:true,attrs:{"name":field.Custom_Field_Name,"rules":field.Mandatory.toLowerCase() === 'yes'
                        ? `required|min:${parseInt(field.Min_Validation)}|max:${parseInt(field.Max_Validation)}|${_vm.dynamicValidationRule(
                            field.Value,
                            field.Validation_Id
                          )}`
                        : `min:${parseInt(field.Min_Validation)}|max:${parseInt(field.Max_Validation)}|${_vm.dynamicValidationRule(
                            field.Value,
                            field.Validation_Id
                          )}`,},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-textarea',{attrs:{"outlined":"","rows":"1","clearable":"","counter":field.Max_Validation,"maxlength":field.Max_Validation,"error-messages":errors},on:{"input":function($event){return _vm.onChangeFields($event, field.Value)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(field.Custom_Field_Name)+" "),(field.Mandatory.toLowerCase() === 'yes')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(field.Value),callback:function ($$v) {_vm.$set(field, "Value", $$v)},expression:"field.Value"}})]}}],null,true)}):(
                      field.Custom_Field_Type.toLowerCase() === 'date'
                    )?_c('ValidationProvider',{ref:`date` + field.Custom_Field_Id,refInFor:true,attrs:{"name":field.Custom_Field_Name,"rules":field.Mandatory.toLowerCase() === 'yes'
                            ? 'required'
                            : '',},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"type":"date","outlined":"","clearable":"","error-messages":errors},on:{"input":function($event){return _vm.onChangeFields($event, field.Value)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(field.Custom_Field_Name)+" "),(field.Mandatory.toLowerCase() === 'yes')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(field.Value),callback:function ($$v) {_vm.$set(field, "Value", $$v)},expression:"field.Value"}})]}}],null,true)}):(
                      field.Custom_Field_Type.toLowerCase() === 'text field'
                    )?_c('ValidationProvider',{ref:`${field.Custom_Field_Id}`,refInFor:true,attrs:{"name":field.Custom_Field_Name,"rules":field.Mandatory.toLowerCase() === 'yes'
                        ? `required|min:${parseInt(field.Min_Validation)}|max:${parseInt(field.Max_Validation)}|${_vm.dynamicValidationRule(
                            field.Value,
                            field.Validation_Id
                          )}`
                        : `min:${parseInt(field.Min_Validation)}|max:${parseInt(field.Max_Validation)}|${_vm.dynamicValidationRule(
                            field.Value,
                            field.Validation_Id
                          )}`,},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","clearable":"","counter":field.Max_Validation,"maxlength":field.Max_Validation,"error-messages":errors},on:{"input":function($event){return _vm.onChangeFields($event, field.Value)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(field.Custom_Field_Name)+" "),(field.Mandatory?.toLowerCase() === 'yes')?_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]):_vm._e()]},proxy:true}],null,true),model:{value:(field.Value),callback:function ($$v) {_vm.$set(field, "Value", $$v)},expression:"field.Value"}})]}}],null,true)}):_vm._e()],1)}),1)],1)],1),_c('v-divider'),_c('div',{staticClass:"mt-3 d-flex justify-end"},[_c('v-btn',{staticStyle:{"border-radius":"10px"},attrs:{"color":"primary","large":"","loading":_vm.saveLoading || _vm.listLoading},on:{"click":function($event){return _vm.validateAddEdit()}}},[_vm._v("Save")])],1),_c('v-overlay',{attrs:{"absolute":"","value":_vm.saveLoading || _vm.listLoading,"color":"#fff","z-index":"1"}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"50"}})],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }