import gql from "graphql-tag";
// ===============
// Queries
// ===============

export const GET_VENDOR_DETAILS = gql`
  query getVendorDetails($urlHash: String!) {
    getVendorDetails(urlHash: $urlHash) {
      errorCode
      message
      vendorDetails {
        vendorId
        vendorType
        vendorName
        businessNumber
        entityTrust
        trusteeName
        gstRegistered
        gstNumber
        serviceOffered
        typeOfIndustry
        street
        city
        stateRegion
        zipCode
        country
        postalStreet
        postalCity
        postalStateRegion
        postalZipCode
        postalCountry
        telephone
        mobileNo
        mobileNoCountryCode
        vendorEmail
        contactNameOfVendor
        noOfEmployeesWithinOrganization
        noOfSubcontractors
        noOfEmployeesToBeAllocatedToContract
        trusteeAuthorizationDocuments
        onboardingFormId
        status
        addedOn
        addedBy
        updatedOn
        updatedBy
        insuranceDetails {
          insuranceId
          typeOfInsurance
          nameOfInsurance
          policyNumber
          nameOfInsurer
          nameOfInsured
          sumInsured
          expiryDate
          description
          documentFile
        }
      }
    }
  }
`;

export const SAVE_VENDOR_DETAILS = gql`
  mutation saveVendorBasicDetails(
    $vendorId: Int
    $vendorType: String
    $vendorName: String
    $businessNumber: String
    $entityTrust: String
    $trusteeName: String
    $gstRegistered: String
    $gstNumber: String
    $serviceOffered: String!
    $typeOfIndustry: String!
    $street: String
    $city: String
    $stateRegion: String
    $zipCode: String
    $country: String
    $postalStreet: String
    $postalCity: String
    $postalStateRegion: String
    $postalZipCode: String
    $postalCountry: String
    $telephone: String
    $mobileNo: String
    $mobileNoCountryCode: String
    $vendorEmail: String
    $contactNameOfVendor: String
    $trusteeAuthorizationDocuments: String
  ) {
    saveVendorBasicDetails(
      vendorId: $vendorId
      vendorType: $vendorType
      vendorName: $vendorName
      businessNumber: $businessNumber
      entityTrust: $entityTrust
      trusteeName: $trusteeName
      gstRegistered: $gstRegistered
      gstNumber: $gstNumber
      serviceOffered: $serviceOffered
      typeOfIndustry: $typeOfIndustry
      street: $street
      city: $city
      stateRegion: $stateRegion
      zipCode: $zipCode
      country: $country
      postalStreet: $postalStreet
      postalCity: $postalCity
      postalStateRegion: $postalStateRegion
      postalZipCode: $postalZipCode
      postalCountry: $postalCountry
      telephone: $telephone
      mobileNo: $mobileNo
      mobileNoCountryCode: $mobileNoCountryCode
      vendorEmail: $vendorEmail
      contactNameOfVendor: $contactNameOfVendor
      trusteeAuthorizationDocuments: $trusteeAuthorizationDocuments
    ) {
      errorCode
      message
    }
  }
`;

export const LIST_EMPLOYEE_REQUIRED_FIELDS = gql`
  query ListDropdownQuery($Org_Code: String!) {
    listLocation(Org_Code: $Org_Code) {
      location {
        Location_Id
        Location_Name
        Location_Type
        __typename
      }
    }
    listDesignation(Org_Code: $Org_Code) {
      designation {
        Designation_Id
        Designation_Name
        __typename
      }
    }
    listDepartment(Org_Code: $Org_Code) {
      department {
        Department_Id
        Department_Name
        __typename
      }
    }
    listValidityType(Org_Code: $Org_Code) {
      validity {
        id
        Type
        __typename
      }
    }
    listWorkSchedule(Org_Code: $Org_Code) {
      work_schedule {
        WorkSchedule_Id
        Title
        __typename
      }
    }
    listManager(Org_Code: $Org_Code) {
      managers {
        Employee_Id
        Emp_First_Name
        Emp_Pref_First_Name
        Emp_Middle_Name
        Emp_Last_Name
        __typename
      }
    }
    listEmployeeType(Org_Code: $Org_Code) {
      employeeType {
        EmpType_Id
        Employee_Type
      }
    }
    __typename
  }
`;

export const IMPORT_CANDIDATE_DETAILS = gql`
  mutation bulkInviteOnboardEmployees(
    $count: Int!
    $vendorId: Int
    $employeeData: [employeeData]
  ) {
    bulkInviteOnboardEmployees(
      count: $count
      vendorId: $vendorId
      employeeData: $employeeData
    ) {
      errorCode
      message
    }
  }
`;

export const SAVE_INSURANCE_DETAILS = gql`
  mutation addUpdateInsurance(
    $insuranceId: Int!
    $vendorId: Int!
    $typeOfInsurance: String!
    $policyNumber: String!
    $nameOfInsurer: String
    $nameOfInsured: String
    $sumOfInsured: Int
    $nameOfInsurance: String
    $expireDate: String
    $description: String
    $fileName: String
  ) {
    addUpdateInsurance(
      insuranceId: $insuranceId
      vendorId: $vendorId
      typeOfInsurance: $typeOfInsurance
      policyNumber: $policyNumber
      nameOfInsurer: $nameOfInsurer
      nameOfInsured: $nameOfInsured
      sumOfInsured: $sumOfInsured
      nameOfInsurance: $nameOfInsurance
      expireDate: $expireDate
      description: $description
      fileName: $fileName
    ) {
      errorCode
      message
    }
  }
`;

export const DELETE_INSURANCE_DETAILS = gql`
  mutation deleteVendorInsurance($insuranceId: Int!) {
    deleteVendorInsurance(insuranceId: $insuranceId) {
      errorCode
      message
    }
  }
`;

export const CANDIDATE_BULK_INVITE = gql`
  mutation bulkInviteOnboardEmployees(
    $count: Int!
    $vendorId: Int
    $employeeData: [employeeData]!
  ) {
    bulkInviteOnboardEmployees(
      count: $count
      vendorId: $vendorId
      employeeData: $employeeData
    ) {
      errorCode
      message
    }
  }
`;

export const SUBMIT_DETAILS = gql`
  mutation changeFinishStatus($vendorId: Int) {
    changeFinishStatus(vendorId: $vendorId) {
      errorCode
      message
    }
  }
`;

export const SAVE_PROJECT_RESOURCES = gql`
  mutation updateProjectResources(
    $vendorId: Int
    $noOfEmployeesWithinOrganization: Int
    $noOfSubcontractors: Int
    $noOfEmployeesToBeAllocatedToContract: Int
    $resourceDetails: String
  ) {
    updateProjectResources(
      vendorId: $vendorId
      noOfEmployeesWithinOrganization: $noOfEmployeesWithinOrganization
      noOfSubcontractors: $noOfSubcontractors
      noOfEmployeesToBeAllocatedToContract: $noOfEmployeesToBeAllocatedToContract
      resourceDetails: $resourceDetails
    ) {
      errorCode
      message
    }
  }
`;

export const GET_VENDOR_DOCUMENTS = gql`
  query listVendorDocuments($vendorId: Int!) {
    listVendorDocuments(vendorId: $vendorId) {
      errorCode
      message
      vendorDocuments {
        Document_Id
        Vendor_Id
        Category_Id
        Category_Name
        Document_Type_Id
        Document_Type
        Document_Sub_Type_Id
        Document_Sub_Type
        Document_Name
        Effective_Date
        End_Date
        File_Name
      }
    }
  }
`;

export const ADD_UPDATE_VENDOR_DOCUMENTS = gql`
  mutation addUpdateVendorDocuments(
    $Document_Id: Int
    $Vendor_Id: Int!
    $Category_Id: Int!
    $Document_Type_Id: Int!
    $Document_Sub_Type_Id: Int!
    $Document_Name: String!
    $File_Name: String!
    $File_Size: String
    $Effective_Date: String
    $End_Date: String
    $checkAccess: Int
  ) {
    addUpdateVendorDocuments(
      Document_Id: $Document_Id
      Vendor_Id: $Vendor_Id
      Category_Id: $Category_Id
      Document_Type_Id: $Document_Type_Id
      Document_Sub_Type_Id: $Document_Sub_Type_Id
      Document_Name: $Document_Name
      File_Name: $File_Name
      File_Size: $File_Size
      Effective_Date: $Effective_Date
      End_Date: $End_Date
      checkAccess: $checkAccess
    ) {
      errorCode
      message
    }
  }
`;

export const DELETE_VENDOR_DOCUMENTS = gql`
  mutation deleteVendorDocument($documentId: Int!, $checkAccess: Int) {
    deleteVendorDocument(documentId: $documentId, checkAccess: $checkAccess) {
      errorCode
      message
    }
  }
`;

export const GET_DYNAMIC_FORM_DETAILS = gql`
  query getDynamicFormDetails(
    $vendorBased: Int
    $templateId: Int
    $vendorId: Int!
  ) {
    getDynamicFormDetails(
      vendorBased: $vendorBased
      templateId: $templateId
      vendorId: $vendorId
    ) {
      errorCode
      message
      dynamicFormDetails {
        Template_Name
        Form_Template
        Response_Id
        Required
      }
    }
  }
`;

export const ADD_UPDATE_VENDOR_DYNAMIC_FORM = gql`
  mutation addUpdateVendorDynamicForm(
    $response: String!
    $status: String!
    $checkAccess: Int!
    $responseId: Int
    $vendorId: Int!
  ) {
    addUpdateVendorDynamicForm(
      response: $response
      status: $status
      checkAccess: $checkAccess
      responseId: $responseId
      vendorId: $vendorId
    ) {
      errorCode
      message
    }
  }
`;
export const GET_VENDOR_BANK_DETAILS = gql`
  query getVendorBankDetails($vendorId: Int!) {
    getVendorBankDetails(vendorId: $vendorId) {
      errorCode
      message
      vendorBankDetails {
        Vendor_Id
        Vendor_Bank_Id
        Bank_Account_Number
        Bank_Name
        Bank_Id
        Branch_Name
        IFSC_Code
        Street
        City
        State
        Zip
        Account_Type_Id
        Credit_Account
        Beneficiary_Id
        Status
        BSB_Code
      }
    }
  }
`;
export const ADD_UPDATE_VENDOR_BANK_DETAILS = gql`
  mutation addUpdateVendorBankDetails(
    $Vendor_Id: Int
    $Vendor_Bank_Id: Int
    $Bank_Account_Number: String
    $Bank_Name: String
    $Bank_Id: Int
    $Branch_Name: String
    $IFSC_Code: String
    $Street: String
    $City: String
    $State: String
    $Zip: String
    $Account_Type_Id: Int
    $Credit_Account: String
    $Beneficiary_Id: Int
    $Status: String
    $BSB_Code: String
    $checkAccess: Int
  ) {
    addUpdateVendorBankDetails(
      Vendor_Id: $Vendor_Id
      Vendor_Bank_Id: $Vendor_Bank_Id
      Bank_Account_Number: $Bank_Account_Number
      Bank_Name: $Bank_Name
      Bank_Id: $Bank_Id
      Branch_Name: $Branch_Name
      IFSC_Code: $IFSC_Code
      Street: $Street
      City: $City
      State: $State
      Zip: $Zip
      Account_Type_Id: $Account_Type_Id
      Credit_Account: $Credit_Account
      Beneficiary_Id: $Beneficiary_Id
      Status: $Status
      BSB_Code: $BSB_Code
      checkAccess: $checkAccess
    ) {
      errorCode
      message
    }
  }
`;
