<template>
  <div v-if="mounted">
    <div class="full-height">
      <v-row justify="center" style="height: 90%">
        <v-col cols="11">
          <v-card class="pa-6 my-4" width="100%" raised height="100%">
            <div class="d-flex align-center">
              <v-icon color="primary" @click="onClickPrevBtn()"
                >arrow_back</v-icon
              >
              <div class="pl-2 text-h5 primary--text font-weight-medium">
                Bank Information
              </div>
            </div>
            <v-form>
              <ValidationObserver ref="bankInfoObserver">
                <v-row class="ma-4">
                  <v-col
                    v-if="
                      !isAustralian &&
                      getFieldAlias(135).Field_Visiblity == 'Yes'
                    "
                    cols="12"
                    lg="4"
                    xl="4"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <ValidationProvider
                      ref="ifscProvider"
                      v-slot="{ errors }"
                      :name="getFieldAlias(135).Field_Alias"
                      :rules="
                        getFieldAlias(135).Mandatory_Field == 'Yes'
                          ? 'required|min:6|ifscCode'
                          : 'min:6|ifscCode'
                      "
                    >
                      <v-text-field
                        :id="'ifsc'"
                        v-model="ifsc"
                        outlined
                        :label="getFieldAlias(135).Field_Alias"
                        @change="ifscCall"
                        @input="onChangeField"
                        :error-messages="errors"
                      >
                        <template v-slot:label>
                          {{ getFieldAlias(135).Field_Alias }}
                          <span
                            style="color: red"
                            v-if="getFieldAlias(135).Mandatory_Field == 'Yes'"
                            >*</span
                          >
                        </template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
                    <ValidationProvider
                      ref="bankNameProvider"
                      v-slot="{ errors }"
                      name="Bank Name"
                      rules="required"
                    >
                      <v-autocomplete
                        :id="'BankName'"
                        v-model="bankNameId"
                        outlined
                        :items="bankNameList"
                        label="Bank Name*"
                        item-text="Bank_Name"
                        item-value="Bank_Id"
                        :error-messages="errors"
                        :search-input.sync="bankNameSearch"
                        @change="onChangeField"
                      >
                        <template v-slot:label>
                          Bank Name
                          <span style="color: red">*</span>
                        </template>
                      </v-autocomplete>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
                    <ValidationProvider
                      ref="bankLocationProvider"
                      v-slot="{ errors }"
                      name="Branch Location"
                      rules="required|alphaNumSpaceWithSymbols|max:50"
                    >
                      <v-text-field
                        :id="'branchLocation'"
                        v-model="branchLocation"
                        outlined
                        label="Branch Location*"
                        :counter="50"
                        :maxlength="50"
                        :error-messages="errors"
                        @input="onChangeField"
                      >
                        <template v-slot:label>
                          Bank Location
                          <span style="color: red">*</span>
                        </template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    v-if="!isAustralian"
                    cols="12"
                    lg="4"
                    xl="4"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <ValidationProvider
                      ref="streetProvider"
                      v-slot="{ errors }"
                      name="Street"
                      rules="alphaNumSpaceWithSymbols|max:300"
                    >
                      <v-text-field
                        :id="'street'"
                        v-model="street"
                        outlined
                        :label="getFieldAlias(237).Field_Alias"
                        :counter="300"
                        :maxlength="300"
                        :error-messages="errors"
                        @input="onChangeField"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    v-if="!isAustralian"
                    cols="12"
                    lg="4"
                    xl="4"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <ValidationProvider
                      ref="cityProvider"
                      v-slot="{ errors }"
                      name="City"
                      rules="alphaNumSpaceWithSymbols"
                    >
                      <v-text-field
                        :id="'city'"
                        v-model="city"
                        outlined
                        label="City"
                        :counter="50"
                        :maxlength="50"
                        :error-messages="errors"
                        @input="onChangeField"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    v-if="!isAustralian"
                    cols="12"
                    lg="4"
                    xl="4"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <ValidationProvider
                      ref="stateProvider"
                      v-slot="{ errors }"
                      name="State"
                      rules="alphaNumSpaceWithSymbols"
                    >
                      <v-text-field
                        :id="'state'"
                        v-model="state"
                        outlined
                        label="State/Province"
                        :counter="20"
                        :maxlength="20"
                        :error-messages="errors"
                        @input="onChangeField"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    v-if="
                      !isAustralian &&
                      getFieldAlias(146).Field_Visiblity == 'Yes'
                    "
                    cols="12"
                    lg="4"
                    xl="4"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <ValidationProvider
                      ref="pinCode"
                      v-slot="{ errors }"
                      name="PinCode"
                      :rules="
                        getFieldAlias(146).Mandatory_Field == 'Yes'
                          ? 'required|numeric'
                          : 'numeric'
                      "
                    >
                      <v-text-field
                        :id="'pinCode'"
                        v-model="pinCode"
                        outlined
                        :label="getFieldAlias(146).Field_Alias"
                        :counter="8"
                        :maxlength="8"
                        :error-messages="errors"
                        @input="onChangeField"
                      >
                        <template v-slot:label>
                          {{ getFieldAlias(146).Field_Alias }}
                          <span
                            style="color: red"
                            v-if="getFieldAlias(146).Mandatory_Field == 'Yes'"
                            >*</span
                          >
                        </template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    cols="12"
                    lg="4"
                    xl="4"
                    md="6"
                    sm="12"
                    xs="12"
                    v-if="
                      getFieldAlias(346) &&
                      getFieldAlias(346).Field_Visiblity == 'Yes'
                    "
                  >
                    <ValidationProvider
                      ref="acNameProvider"
                      v-slot="{ errors }"
                      :name="getFieldAlias(346).Field_Alias"
                      :rules="
                        getFieldAlias(346).Mandatory_Field == 'Yes'
                          ? 'required|multilingualNameNumericValidation|max:100'
                          : 'multilingualNameNumericValidation|max:100'
                      "
                    >
                      <v-text-field
                        :id="'acName'"
                        v-model="bankAccountName"
                        outlined
                        counter="100"
                        label="Account Name"
                        :error-messages="errors"
                      >
                        <template v-slot:label>
                          {{ getFieldAlias(346).Field_Alias }}
                          <span
                            style="color: red"
                            v-if="getFieldAlias(346).Mandatory_Field == 'Yes'"
                            >*</span
                          >
                        </template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
                    <ValidationProvider
                      ref="acNoProvider"
                      v-slot="{ errors }"
                      name="Account Number"
                      rules="required|alphaNumeric"
                    >
                      <v-text-field
                        :id="'acNo'"
                        v-model="accountNumber"
                        outlined
                        label="Account Number*"
                        :counter="50"
                        :maxlength="50"
                        :error-messages="errors"
                        @input="onChangeField"
                      >
                        <template v-slot:label>
                          Account Number
                          <span style="color: red">*</span>
                        </template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" lg="4" xl="4" md="6" sm="12" xs="12">
                    <ValidationProvider
                      ref="accountType"
                      v-slot="{ errors }"
                      name="Account Type"
                      rules="required"
                    >
                      <v-autocomplete
                        :id="'accountType'"
                        v-model="accountType"
                        outlined
                        :items="accountTypeList"
                        label="Account Type*"
                        item-text="Account_Type"
                        item-value="Account_Type_Id"
                        :error-messages="errors"
                        :search-input.sync="accountTypeSearch"
                        @change="onChangeField"
                      >
                        <template v-slot:label>
                          Account Type
                          <span style="color: red">*</span>
                        </template>
                      </v-autocomplete>
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    v-if="isAustralian"
                    cols="12"
                    lg="4"
                    xl="4"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <ValidationProvider
                      ref="bsbProvider"
                      v-slot="{ errors }"
                      name="BSB"
                      rules="required|min:6|max:7|bsbCode"
                    >
                      <v-text-field
                        :id="'bsb'"
                        v-model="bsbCode"
                        outlined
                        label="BSB*"
                        :error-messages="errors"
                        @input="onChangeField"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col v-if="isAustralian" cols="12">
                    <div class="pl-2 text-h5 primary--text font-weight-medium">
                      Tax Information
                    </div>
                  </v-col>
                  <v-col
                    v-if="isAustralian"
                    cols="12"
                    lg="4"
                    xl="4"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <ValidationProvider
                      ref="taxFileNumberProvider"
                      v-slot="{ errors }"
                      name="Tax File Number"
                      rules="required|min:9|max:15"
                    >
                      <v-text-field
                        :id="'taxFileNumber'"
                        v-model="taxFileNumber"
                        outlined
                        label="Tax File Number*"
                        :error-messages="errors"
                        @input="onChangeField"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col v-if="isAustralian" cols="12">
                    <div class="pl-2 text-h5 primary--text font-weight-medium">
                      Superannuation
                    </div>
                  </v-col>
                  <v-col
                    v-if="isAustralian"
                    cols="12"
                    lg="4"
                    xl="4"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <ValidationProvider
                      ref="superannuationTypeProvider"
                      v-slot="{ errors }"
                      name="Type"
                      rules="required"
                    >
                      <v-autocomplete
                        :id="'Type'"
                        v-model="superannuationType"
                        outlined
                        :items="typeList"
                        label="Type*"
                        :error-messages="errors"
                        @change="onChangeField"
                      ></v-autocomplete>
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    v-if="
                      superannuationType &&
                      superannuationType !== 'Employer Nominated' &&
                      isAustralian
                    "
                    cols="12"
                    lg="4"
                    xl="4"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <ValidationProvider
                      ref="fundABNProvider"
                      v-slot="{ errors }"
                      name="Fund ABN"
                      rules="required|min:11"
                    >
                      <v-text-field
                        :id="'fundABN'"
                        v-model="fundABN"
                        outlined
                        label="Fund ABN*"
                        :error-messages="errors"
                        @input="onChangeField"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    v-if="
                      superannuationType &&
                      superannuationType !== 'Employer Nominated' &&
                      isAustralian
                    "
                    cols="12"
                    lg="4"
                    xl="4"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <ValidationProvider
                      ref="fundNameProvider"
                      v-slot="{ errors }"
                      name="Fund Name"
                      rules="required"
                    >
                      <v-text-field
                        :id="'fundName'"
                        v-model="fundName"
                        outlined
                        label="Fund Name*"
                        :error-messages="errors"
                        @input="onChangeField"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12">
                    <div
                      v-if="
                        superannuationType &&
                        superannuationType !== 'Employer Nominated' &&
                        !isManualFundAddress &&
                        isAustralian
                      "
                      class="d-flex py-4"
                      style="width: 100%"
                    >
                      <vue-google-autocomplete
                        id="map"
                        classname="form-control pa-3"
                        style="width: 100%; border: 1px solid #e0e0e0"
                        placeholder="Fund Address"
                        v-on:placechanged="setFundAddress"
                        :enable-geolocation="true"
                        country="au"
                      >
                      </vue-google-autocomplete>
                    </div>
                    <div
                      v-if="
                        superannuationType &&
                        superannuationType !== 'Employer Nominated'
                      "
                      style="width: 40%"
                      class="ml-3"
                    >
                      <v-checkbox
                        v-model="isManualFundAddress"
                        label="No autocomplete address found"
                        @change="onChangeField"
                      ></v-checkbox>
                    </div>
                  </v-col>
                  <v-col
                    v-if="
                      superannuationType &&
                      superannuationType !== 'Employer Nominated' &&
                      isManualFundAddress &&
                      isAustralian
                    "
                    cols="12"
                    lg="4"
                    xl="4"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <ValidationProvider
                      ref="fundAddressProvider"
                      v-slot="{ errors }"
                      name="Fund Address"
                      rules="required"
                    >
                      <v-text-field
                        :id="'fundAddress'"
                        v-model="fundAddress"
                        outlined
                        label="Fund Address*"
                        :error-messages="errors"
                        @input="onChangeField"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    v-if="
                      superannuationType &&
                      superannuationType !== 'Employer Nominated' &&
                      isManualFundAddress &&
                      isAustralian
                    "
                    cols="12"
                    lg="4"
                    xl="4"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <ValidationProvider
                      ref="suburbOrTownProvider"
                      v-slot="{ errors }"
                      name="Suburb/Town"
                      rules="required"
                    >
                      <v-text-field
                        :id="'suburbOrTown'"
                        v-model="suburbOrTown"
                        outlined
                        label="Suburb/Town*"
                        :error-messages="errors"
                        @input="onChangeField"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    v-if="
                      superannuationType &&
                      superannuationType !== 'Employer Nominated' &&
                      isManualFundAddress &&
                      isAustralian
                    "
                    cols="12"
                    lg="4"
                    xl="4"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <ValidationProvider
                      ref="stateOrTerritoryProvider"
                      v-slot="{ errors }"
                      name="State or Territory"
                      rules="required"
                    >
                      <v-text-field
                        :id="'stateOrTerritory'"
                        v-model="stateOrTerritory"
                        outlined
                        label="State or Territory*"
                        :error-messages="errors"
                        @input="onChangeField"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    v-if="
                      superannuationType &&
                      superannuationType !== 'Employer Nominated' &&
                      isManualFundAddress
                    "
                    cols="12"
                    lg="4"
                    xl="4"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <ValidationProvider
                      ref="postCodeProvider"
                      v-slot="{ errors }"
                      name="Post Code"
                      rules="required"
                    >
                      <v-text-field
                        :id="'postCode'"
                        v-model="postCode"
                        outlined
                        label="Post Code*"
                        :error-messages="errors"
                        @input="onChangeField"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    v-if="
                      superannuationType &&
                      superannuationType !== 'Employer Nominated' &&
                      isAustralian
                    "
                    cols="12"
                    lg="4"
                    xl="4"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <ValidationProvider
                      ref="fundPhoneProvider"
                      v-slot="{ errors }"
                      name="Fund Phone"
                      rules="required"
                    >
                      <v-text-field
                        :id="'fundPhone'"
                        v-model="fundPhone"
                        outlined
                        label="Fund Phone*"
                        :error-messages="errors"
                        @input="onChangeField"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    v-if="superannuationType === 'SMSF' && isAustralian"
                    cols="12"
                    lg="4"
                    xl="4"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <ValidationProvider
                      ref="fundElectronicServiceAddressProvider"
                      v-slot="{ errors }"
                      name="Fund Electronic Service Address"
                      rules="required"
                    >
                      <v-text-field
                        :id="'fundElectronicServiceAddress'"
                        v-model="fundElectronicServiceAddress"
                        outlined
                        label="Fund Electronic Service Address*"
                        :error-messages="errors"
                        @input="onChangeField"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    v-if="superannuationType === 'Super Fund' && isAustralian"
                    cols="12"
                    lg="4"
                    xl="4"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <ValidationProvider
                      ref="uniqueSuperannuationIdentifierProvider"
                      v-slot="{ errors }"
                      name="Unique Superannuation Identifier"
                      rules="required"
                    >
                      <v-text-field
                        :id="'uniqueSuperannuationIdentifier'"
                        v-model="uniqueSuperannuationIdentifier"
                        outlined
                        label="Unique Superannuation Identifier*"
                        :error-messages="errors"
                        @input="onChangeField"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    v-if="superannuationType === 'Super Fund' && isAustralian"
                    cols="12"
                    lg="4"
                    xl="4"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <ValidationProvider
                      ref="accountNameProvider"
                      v-slot="{ errors }"
                      name="Account Name"
                      rules="required"
                    >
                      <v-text-field
                        :id="'accountName'"
                        v-model="accountName"
                        outlined
                        label="Account Name*"
                        :error-messages="errors"
                        @input="onChangeField"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    v-if="superannuationType === 'Super Fund' && isAustralian"
                    cols="12"
                    lg="4"
                    xl="4"
                    md="6"
                    sm="12"
                    xs="12"
                  >
                    <ValidationProvider
                      ref="memberNumberProvider"
                      v-slot="{ errors }"
                      name="Member Number"
                      rules="required"
                    >
                      <v-text-field
                        :id="'memberNumber'"
                        v-model="memberNumber"
                        outlined
                        label="Member Number*"
                        :error-messages="errors"
                        @input="onChangeField"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </ValidationObserver>
            </v-form>
            <v-divider></v-divider>
            <div class="mt-3 d-flex justify-end">
              <v-btn
                :disabled="!isFormDirty"
                color="primary"
                style="border-radius: 10px"
                large
                :loading="saveLoading"
                @click="onSaveBankInfo()"
                >Save</v-btn
              >
              <v-btn
                class="ml-2"
                elevation="2"
                text
                color="primary"
                style="border-radius: 10px"
                large
                @click="onSkipBankDetails()"
                >Skip</v-btn
              >
            </div>
            <v-overlay
              absolute
              :value="bankLoading || saveLoading || superannuationLoading"
              color="#fff"
              z-index="1"
            >
              <v-progress-circular
                color="primary"
                indeterminate
                size="50"
              ></v-progress-circular>
            </v-overlay> </v-card
        ></v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {
  LIST_BANK,
  GET_BANK_DETAILS,
  UPDATE_BANK,
  ADD_BANK,
  GET_SUPERANNUATION,
  ADD_SUPERANNUATION,
  UPDATE_SUPERANNUATION,
  LIST_ACCOUNT_TYPE,
  GET_FORM_FIELDS_AND_TYPE,
} from "@/graphql/OnboardingQueries";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import axios from "axios";
import { scrollScreenToTop } from "../../../helper";

export default {
  name: "BankInformation",

  components: {
    VueGoogleAutocomplete,
  },

  data: () => ({
    bankName: "",
    bankNameId: "",
    branchLocation: "",
    accountNumber: "",
    accountType: "",
    accountTypeList: [],
    mounted: false,
    ifsc: "",
    bsbCode: "",
    saveLoading: false,
    bankLoading: true,
    superannuationLoading: true,
    isUpdate: false,
    isSuperannuationUpdate: false,
    bankNameList: [],
    typeList: ["Super Fund", "SMSF", "Employer Nominated"],
    superannuationType: "",
    taxFileNumber: "",
    fundABN: "",
    fundName: "",
    fundAddress: "",
    suburbOrTown: "",
    stateOrTerritory: "",
    postCode: "",
    fundPhone: "",
    fundElectronicServiceAddress: "",
    uniqueSuperannuationIdentifier: "",
    accountName: "",
    memberNumber: "",
    apiCallCount: 0,
    isManualFundAddress: false,
    isAustralian: 0,
    city: "",
    street: "",
    state: "",
    pinCode: "",
    accountTypeSearch: "",
    bankNameSearch: "",
    bankAccountName: "",
    isFormDirty: false,
    formList: [],
  }),

  mounted() {
    scrollScreenToTop();
    //Get the country from localstorage
    let country = localStorage.getItem("country");
    if (country && country.toLowerCase() === "au") {
      this.isAustralian = 1;
    }
    this.mounted = true;
    this.getFieldProps();
  },

  apollo: {
    // listing account type
    listAccountType: {
      query: LIST_ACCOUNT_TYPE,
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
          Url_Hash: localStorage.getItem("urlHash"),
        };
      },
      result({ data }) {
        this.accountTypeList = data.listAccountType.accountType;
      },
    },
    listBankName: {
      query: LIST_BANK,
      client: "apolloClientA",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
          Url_Hash: localStorage.getItem("urlHash"),
        };
      },
      result({ data }) {
        if (data && data.listBankName) {
          this.bankNameList = data.listBankName.bankName;
        }
      },
    },
    getBankDetails: {
      query: GET_BANK_DETAILS,
      client: "apolloClientA",
      fetchPolicy: "no-cache",
      variables() {
        return {
          Org_Code: localStorage.getItem("orgCode"),
          Url_Hash: localStorage.getItem("urlHash"),
        };
      },
      result({ data }) {
        if (data && data.getBankDetails) {
          const bankRes = data.getBankDetails;
          this.isUpdate = true;
          this.accountNumber = bankRes.Bank_Account_Number;
          this.bankName = bankRes.Bank_Name.toUpperCase();
          this.bankNameId = bankRes.Emp_Bank_Id;
          this.branchLocation = bankRes.Branch_Name;
          this.bsbCode = bankRes.BSB_Code;
          this.ifsc = bankRes.IFSC_Code;
          this.accountType = this.accountTypeList.find((el) => {
            if (el.Account_Type === bankRes.Account_Type) {
              return el;
            }
          });
          if (this.accountType && this.accountType.Account_Type_Id) {
            this.accountType = this.accountType.Account_Type_Id;
          }
          this.street = bankRes.Street;
          this.city = bankRes.City;
          this.state = bankRes.State;
          this.pinCode = bankRes.Zip;
          this.bankAccountName = bankRes?.Bank_Account_Name;
        } else {
          this.isUpdate = false;
        }
        this.bankLoading = false;
      },
      // Error handling
      error(err) {
        this.bankLoading = false;
        let snackbarData = {
          isOpen: true,
          message:
            err.graphQLErrors.length !== 0
              ? err.graphQLErrors[0].message
              : "Something went wrong. It could be a possibility due to network connectivity error..",
          type: "warning",
        };
        this.showAlert(snackbarData);
        this.personalInfoLoading = false;
      },
    },
    retrieveSuperannuationDetails: {
      query: GET_SUPERANNUATION,
      client: "apolloClientB",
      fetchPolicy: "no-cache",
      variables() {
        return {
          urlHash: localStorage.getItem("urlHash"),
        };
      },
      result({ data }) {
        if (data && data.retrieveSuperannuationDetails) {
          let { superannuationDetails } = data.retrieveSuperannuationDetails;
          if (superannuationDetails && superannuationDetails.length > 0) {
            let superannuationDetail = superannuationDetails[0];
            this.isSuperannuationUpdate = true;
            this.taxFileNumber = superannuationDetail.taxFileNumber;
            this.superannuationType = superannuationDetail.superannuationType;
            this.fundABN = superannuationDetail.fundABN;
            this.fundName = superannuationDetail.fundName;
            this.fundAddress = superannuationDetail.fundAddress;
            this.suburbOrTown = superannuationDetail.suburbOrTown;
            this.stateOrTerritory = superannuationDetail.stateOrTerritory;
            this.postCode = superannuationDetail.postCode;
            this.fundPhone = superannuationDetail.fundPhone;
            this.fundElectronicServiceAddress =
              superannuationDetail.fundElectronicServiceAddress;
            this.uniqueSuperannuationIdentifier =
              superannuationDetail.uniqueSuperannuationIdentifier;
            this.accountName = superannuationDetail.accountName;
            this.memberNumber = superannuationDetail.memberNumber;
          } else {
            this.isSuperannuationUpdate = false;
          }
        }
        this.superannuationLoading = false;
      },
      // Error handling
      error() {
        this.superannuationLoading = false;
      },
    },
  },

  watch: {
    ifsc() {},
    apiCallCount(val) {
      if (val >= 2) {
        this.$emit("bank-saved");
      }
    },
    bankNameId() {
      this.bankNameSearch = "";
    },
    accountType() {
      this.accountTypeSearch = "";
    },
  },

  methods: {
    //  calling api to prefil other details through giving ifsc
    ifscCall() {
      const headers = {
        "DY-X-Authorization": "001cf6dba3b7d1b91cef85e9587058fa704f7af3",
      };
      axios
        .get(
          "https://ifsc.razorpay.com/" + this.ifsc,
          { crossdomain: true },
          {
            headers: headers,
          }
        )
        .then((res) => {
          let bank = this.bankNameList.find((el) => {
            if (el.Bank_Name === res.data.BANK.toUpperCase()) {
              return el;
            } else if (el.Bank_Name.match(res.data.BANK.toUpperCase())) {
              return el;
            }
          });
          this.bankNameId = bank.Bank_Id;
          this.city = res.data.CITY;
          this.street = res.data.ADDRESS.split("-")[0];
          this.state = res.data.STATE;
          this.branchLocation = res.data.BRANCH;
        })
        .catch(() => {
          let snackbarData = {
            isOpen: true,
            message: "Invalid " + this.getFieldAlias(135).Field_Alias,
            type: "warning",
          };
          this.showAlert(snackbarData);
        });
    },
    setFundAddress(addressData) {
      if (addressData.street_number) {
        if (addressData.subpremise) {
          var street =
            addressData.subpremise +
            "/" +
            addressData.street_number +
            " " +
            addressData.route;
        } else if (addressData.street_number) {
          street = addressData.street_number + " " + addressData.route;
        } else {
          street = addressData.route;
        }
        this.fundAddress =
          street +
          ", " +
          addressData.locality +
          ", " +
          addressData.administrative_area_level_1 +
          ", " +
          addressData.country +
          " " +
          addressData.postal_code;
        this.suburbOrTown = addressData.locality;
        this.stateOrTerritory = addressData.administrative_area_level_1;
        this.postCode = addressData.postal_code;
      } else {
        this.isManualFundAddress = true;
        let snackbarData = {
          isOpen: true,
          message: "Please enter the complete address for auto completion",
          type: "warning",
        };
        this.showAlert(snackbarData);
      }
    },
    onClickPrevBtn() {
      this.$emit("back-to-prev");
    },
    onSkipBankDetails() {
      this.$emit("bank-saved");
    },
    onChangeField() {
      this.isFormDirty = true;
    },
    onSaveBankInfo() {
      let vm = this;
      vm.$refs.bankInfoObserver.validate().then((isValid) => {
        let invalidFields = [];
        Object.keys(this.$refs).forEach((refName) => {
          const field = this.$refs[refName];
          if (field && field.errors && field.errors.length > 0) {
            invalidFields.push(refName);
          }
        });
        if (invalidFields.length > 0) {
          let firstField = this.$refs[invalidFields[0]];
          firstField.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        if (isValid) {
          vm.saveLoading = true;
          vm.apiCallCount = 0;
          let bank = vm.bankNameList.find((el) => {
            if (el.Bank_Id === vm.bankNameId) {
              return el;
            }
          });
          vm.bankName = bank.Bank_Name;
          if (vm.isUpdate) {
            vm.updateBankDetails();
            if (!vm.isAustralian) {
              vm.apiCallCount += 1;
            }
          } else {
            vm.addBankDetails();
            if (!vm.isAustralian) {
              vm.apiCallCount += 1;
            }
          }
          if (vm.isSuperannuationUpdate && vm.isAustralian) {
            vm.updateSuperannuationDetails();
          } else if (vm.isAustralian) {
            vm.addSuperannuationDetails();
          }
        }
      });
    },
    addBankDetails() {
      let vm = this;
      vm.$apollo
        .mutate({
          mutation: ADD_BANK,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            Bank_Account_Number: vm.accountNumber,
            Bank_Name: vm.bankName,
            Branch_Name: vm.branchLocation,
            IFSC_Code: vm.ifsc ? vm.ifsc : "",
            Street: vm.street ? vm.street : "",
            City: vm.city ? vm.city : "",
            State: vm.state ? vm.state : "",
            Zip: vm.pinCode ? vm.pinCode : "",
            Emp_Bank_Id: vm.bankNameId,
            Account_Type_Id: vm.accountType ? vm.accountType : 0,
            isAustralian: vm.isAustralian,
            BSB_Code: vm.isAustralian ? vm.bsbCode : "",
            Bank_Account_Name: vm.bankAccountName,
          },
          client: "apolloClientA",
        })
        .then(() => {
          vm.saveLoading = false;
          vm.apiCallCount += 1;
        })
        .catch((addErr) => {
          vm.apiCallCount += 1;
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
        });
    },
    updateBankDetails() {
      let vm = this;
      vm.$apollo
        .mutate({
          mutation: UPDATE_BANK,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            Bank_Account_Number: vm.accountNumber,
            Bank_Name: vm.bankName,
            Branch_Name: vm.branchLocation,
            IFSC_Code: vm.ifsc ? vm.ifsc : "",
            Street: vm.street ? vm.street : "",
            City: vm.city ? vm.city : "",
            State: vm.state ? vm.state : "",
            Zip: vm.pinCode ? vm.pinCode : "",
            Emp_Bank_Id: vm.bankNameId,
            Account_Type_Id: vm.accountType ? vm.accountType : 0,
            isAustralian: vm.isAustralian,
            BSB_Code: vm.isAustralian ? vm.bsbCode : "",
            Bank_Account_Name: vm.bankAccountName,
          },
          client: "apolloClientA",
        })
        .then(() => {
          vm.saveLoading = false;
          vm.apiCallCount += 1;
        })
        .catch((updateErr) => {
          vm.apiCallCount += 1;
          let snackbarData = {
            isOpen: true,
            message:
              updateErr.graphQLErrors.length !== 0
                ? updateErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
        });
    },
    addSuperannuationDetails() {
      let vm = this;
      vm.$apollo
        .mutate({
          mutation: ADD_SUPERANNUATION,
          variables: {
            urlHash: localStorage.getItem("urlHash"),
            taxFileNumber: vm.taxFileNumber,
            superannuationType: vm.superannuationType,
            fundABN: vm.fundABN,
            fundName: vm.fundName,
            fundAddress: vm.fundAddress,
            suburbOrTown: vm.suburbOrTown,
            stateOrTerritory: vm.stateOrTerritory,
            postCode: vm.postCode,
            fundPhone: vm.fundPhone,
            fundElectronicServiceAddress: vm.fundElectronicServiceAddress,
            uniqueSuperannuationIdentifier: vm.uniqueSuperannuationIdentifier,
            accountName: vm.accountName,
            memberNumber: vm.memberNumber,
          },
          client: "apolloClientC",
        })
        .then(() => {
          vm.saveLoading = false;
          vm.apiCallCount += 1;
        })
        .catch((addErr) => {
          vm.apiCallCount += 1;
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
        });
    },
    updateSuperannuationDetails() {
      let vm = this;
      vm.$apollo
        .mutate({
          mutation: UPDATE_SUPERANNUATION,
          variables: {
            urlHash: localStorage.getItem("urlHash"),
            taxFileNumber: vm.taxFileNumber,
            superannuationType: vm.superannuationType,
            fundABN: vm.fundABN,
            fundName: vm.fundName,
            fundAddress: vm.fundAddress,
            suburbOrTown: vm.suburbOrTown,
            stateOrTerritory: vm.stateOrTerritory,
            postCode: vm.postCode,
            fundPhone: vm.fundPhone,
            fundElectronicServiceAddress: vm.fundElectronicServiceAddress,
            uniqueSuperannuationIdentifier: vm.uniqueSuperannuationIdentifier,
            accountName: vm.accountName,
            memberNumber: vm.memberNumber,
          },
          client: "apolloClientC",
        })
        .then(() => {
          vm.saveLoading = false;
          vm.apiCallCount += 1;
        })
        .catch((addErr) => {
          vm.apiCallCount += 1;
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
        });
    },

    getFieldAlias(fieldId) {
      return this.formList.find((field) => field.Field_Id === fieldId) || {};
    },

    getFieldProps() {
      let vm = this;
      vm.saveLoading = true;
      this.$apollo
        .query({
          query: GET_FORM_FIELDS_AND_TYPE,
          variables: {
            form_Id: 178,
          },
          client: "apolloClientB",
        })
        .then((res) => {
          if (res.data.getFormFeildsByFormIdAndTab) {
            this.formList = res.data.getFormFeildsByFormIdAndTab.formFields;
          }
          vm.saveLoading = false;
        })
        .catch(() => {
          vm.saveLoading = false;
        });
    },
    // show error or success message
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>
