<template>
  <div class="full-height">
    <v-row justify="center" style="height: 90%">
      <v-col cols="11">
        <v-row class="full-height my-6" style="height: 100%">
          <v-col v-if="windowWidth > 960">
            <v-card class="pa-4 text-center" raised height="100%" width="100%">
              <v-card
                v-for="(detail, index) in basicDetails"
                :key="detail.type"
                class="d-flex my-3 pa-3"
                min-height="50"
                :color="index === selectedInfo ? 'primary' : ''"
              >
                <v-icon :color="index === selectedInfo ? 'white' : 'primary'">{{
                  detail.icon
                }}</v-icon>
                <div
                  class="pl-2"
                  :class="
                    index === selectedInfo ? 'white--text' : 'primary--text'
                  "
                >
                  {{ detail.heading }}
                </div>
              </v-card>
            </v-card>
          </v-col>
          <v-col cols="12" lg="9" md="9" sm="12" xl="9">
            <v-card
              raised
              height="100%"
              width="100%"
              min-height="300"
              class="pa-3"
            >
              <div v-if="!personalInfoLoading">
                <div class="d-flex">
                  <v-icon color="primary" @click="onClickPrevBtn()"
                    >arrow_back</v-icon
                  >
                  <div class="pl-2 text-h5 primary--text font-weight-medium">
                    {{ stepHeading() }}
                  </div>
                </div>
                <WorkerCompensation
                  ref="workerCompensation"
                  v-if="selectedInfo === 0"
                  :personal-info="workerCompensationInfo"
                  @file-event-triggered="isFileLoading = $event"
                ></WorkerCompensation>
                <PublicLiability
                  ref="publicLiability"
                  v-else-if="selectedInfo === 1"
                  :personal-info="publicLiabilityInfo"
                  @file-event-triggered="isFileLoading = $event"
                ></PublicLiability>
                <ProfessionalIndemnity
                  ref="professionalIndemnity"
                  v-if="selectedInfo === 2"
                  :personal-info="professionalIndemnityInfo"
                  @file-event-triggered="isFileLoading = $event"
                ></ProfessionalIndemnity>
                <OtherInsurances
                  ref="otherInsurances"
                  v-if="selectedInfo === 3"
                  :insurance-data="otherInsurances"
                  @file-event-triggered="isFileLoading = $event"
                >
                </OtherInsurances>
                <v-divider v-if="selectedInfo !== 2"></v-divider>
                <v-bottom-sheet
                  v-model="openBottomSheet"
                  hide-overlay
                  persistent
                  no-click-animation
                  width="100%"
                  :retain-focus="false"
                >
                  <v-sheet>
                    <div
                      v-if="selectedInfo !== 4"
                      class="pt-3 pa-1 d-flex justify-end"
                    >
                      <v-btn
                        color="primary"
                        style="border-radius: 10px"
                        large
                        :loading="saveLoading"
                        :disabled="isFileLoading"
                        @click="onSaveComplianceInfo()"
                        >Save & Continue</v-btn
                      >
                      <v-btn
                        v-if="selectedInfo === 3 || !isAustralian"
                        class="ml-2"
                        color="primary"
                        text
                        elevation="4"
                        style="border-radius: 10px"
                        large
                        @click="onSkipworkerCompensation()"
                        >Skip</v-btn
                      >
                    </div>
                  </v-sheet>
                </v-bottom-sheet>
              </div>
              <v-overlay
                absolute
                :value="personalInfoLoading || saveLoading"
                color="#fff"
                z-index="1"
              >
                <v-progress-circular
                  color="primary"
                  indeterminate
                  size="50"
                ></v-progress-circular>
              </v-overlay>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const WorkerCompensation = () => import("./WorkerCompensation.vue");
const PublicLiability = () => import("./PublicLiability.vue");
const ProfessionalIndemnity = () => import("./ProfessionalIndemnity.vue");
const OtherInsurances = () => import("./OtherInsurances.vue");
import {
  GET_VENDOR_DETAILS,
  SAVE_INSURANCE_DETAILS,
} from "@/graphql/vendorOnboardQueries";
export default {
  name: "Insurance",

  components: {
    WorkerCompensation,
    ProfessionalIndemnity,
    PublicLiability,
    OtherInsurances,
  },

  data: () => ({
    workerCompensationInfo: {},
    publicLiabilityInfo: {},
    professionalIndemnityInfo: {},
    otherInsurances: [],
    personalInfoLoading: true,
    selectedInfo: 0,
    saveLoading: false,
    openBottomSheet: true,
    stepDetails: {},
    multipleInsuranceDetails: [],
    basicDetails: [
      {
        heading: "Worker Compensation Insurance",
        type: "workerCompensation",
        icon: "engineering",
        value: 0,
      },
      {
        heading: "Public Liability Insurance",
        type: "publicLiability",
        icon: "public",
        value: 1,
      },
      {
        heading: "Professional Indemnity Insurance",
        type: "professionalIndemnity",
        icon: "work",
        value: 2,
      },
      {
        heading: "Other Insurances",
        type: "otherInsurance",
        icon: "shield",
        value: 3,
      },
    ],
    isFileLoading: false,
    isAustralian: 0,
  }),

  mounted() {
    //Get the country from localstorage
    let country = localStorage.getItem("country");
    if (country && country.toLowerCase() === "au") {
      this.isAustralian = 1;
    }
  },

  computed: {
    // current window size
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    // to check the device is mobile based on window size
    isMobileView() {
      return this.$store.state.isMobileWindowSize;
    },
  },

  apollo: {
    getVendorDetails: {
      query: GET_VENDOR_DETAILS,
      client: "apolloClientB",
      fetchPolicy: "no-cache",
      variables() {
        return {
          urlHash: localStorage.getItem("vendorUrlHash"),
        };
      },
      result({ data }) {
        if (
          data &&
          data.getVendorDetails &&
          data.getVendorDetails.vendorDetails &&
          data.getVendorDetails.vendorDetails.insuranceDetails
        ) {
          let insuranceDetails =
            data.getVendorDetails.vendorDetails.insuranceDetails;
          this.otherInsurances = [];
          for (let i = 0; i < insuranceDetails.length; i++) {
            if (
              insuranceDetails[i]["typeOfInsurance"] ===
              "Workers Compensation Insurance"
            ) {
              this.workerCompensationInfo = insuranceDetails[i];
            }
            if (
              insuranceDetails[i]["typeOfInsurance"] ===
              "Public Liability Insurance"
            ) {
              this.publicLiabilityInfo = insuranceDetails[i];
            }
            if (
              insuranceDetails[i]["typeOfInsurance"] ===
              "Professional Indemnity Insurance"
            ) {
              this.professionalIndemnityInfo = insuranceDetails[i];
            }
            if (insuranceDetails[i]["typeOfInsurance"] === "Other Insurance") {
              this.otherInsurances.push(insuranceDetails[i]);
            }
          }
          this.personalInfoDetails = data.getVendorDetails.vendorDetails;
        }
        this.personalInfoLoading = false;
      },
      // Error handling
      error(getVendorDetailsErr) {
        let snackbarData = {
          isOpen: true,
          message:
            getVendorDetailsErr.graphQLErrors.length !== 0
              ? getVendorDetailsErr.graphQLErrors[0].message
              : "Something went wrong. It could be a possibility due to network connectivity error..",
          type: "warning",
        };
        this.showAlert(snackbarData);
        this.personalInfoLoading = false;
      },
    },
  },

  methods: {
    stepHeading() {
      if (this.selectedInfo === 0) {
        return "Worker Compensation Insurance";
      } else if (this.selectedInfo === 1) {
        return "Public Liability Insurance";
      } else if (this.selectedInfo === 2) {
        return "Professional Indemnity Insurance";
      } else {
        return "Other Insurance";
      }
    },
    async onClickNextSection(info) {
      let isFormValid = await this.validateForms();
      if (isFormValid) {
        this.submitComplianceDetails(info);
      }
    },

    async onSaveComplianceInfo() {
      let isFormValid = await this.validateForms();
      if (isFormValid) {
        let nextStep = this.selectedInfo + 1;
        if (this.selectedInfo !== 3) {
          this.submitComplianceDetails(nextStep);
        } else {
          for (let insurance of this.multipleInsuranceDetails) {
            this.stepDetails = insurance;
            this.submitComplianceDetails(nextStep);
          }
        }
      }
    },

    async onSkipworkerCompensation() {
      this.selectedInfo += 1;
      if (this.selectedInfo === 4) {
        this.$emit("move-to-next-section");
      }
    },
    async validateForms() {
      return new Promise((resolve) => {
        switch (this.selectedInfo) {
          case 0:
            this.$refs.workerCompensation
              .validateWorkerCompensationInfo()
              .then((validationResponse) => {
                this.stepDetails =
                  this.$refs.workerCompensation.basicInfoFields();
                resolve(validationResponse);
              });
            break;
          case 1:
            this.$refs.publicLiability
              .validateBasicInfo()
              .then((validationResponse) => {
                this.stepDetails = this.$refs.publicLiability.basicInfoFields();
                resolve(validationResponse);
              });
            break;
          case 2:
            this.$refs.professionalIndemnity
              .validateBasicInfo()
              .then((validationResponse) => {
                this.stepDetails =
                  this.$refs.professionalIndemnity.basicInfoFields();
                resolve(validationResponse);
              });
            break;
          default:
            this.$refs.otherInsurances
              .validateInsurance()
              .then((validationResponse) => {
                this.multipleInsuranceDetails =
                  this.$refs.otherInsurances.formedData();
                resolve(validationResponse);
              });
        }
      });
    },

    onClickPrevBtn() {
      this.personalInfoLoading = true;
      if (this.selectedInfo === 0) {
        this.$emit("back-to-prev");
      } else {
        this.$apollo.queries.getVendorDetails.refetch();
        this.selectedInfo -= 1;
      }
    },

    submitComplianceDetails(nextStep) {
      let vm = this;
      vm.saveLoading = true;
      let vendorId = parseInt(localStorage.getItem("vendorId"));
      let submitObj = {
        Org_Code: localStorage.getItem("orgCode"),
        Url_Hash: localStorage.getItem("vendorUrlHash"),
        vendorId: vendorId,
        ...vm.stepDetails,
      };
      vm.$apollo
        .mutate({
          mutation: SAVE_INSURANCE_DETAILS,
          variables: submitObj,
          client: "apolloClientC",
        })
        .then(() => {
          vm.saveLoading = false;
          vm.selectedInfo = nextStep;
          if (vm.selectedInfo === 4) {
            this.$emit("move-to-next-section");
          }
        })
        .catch((updateErr) => {
          vm.saveLoading = false;
          let snackbarData = {
            isOpen: true,
            message:
              updateErr.graphQLErrors.length !== 0
                ? updateErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
        });
    },

    // show error or success message
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>
