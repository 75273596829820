<template>
  <div>
    <div class="full-height">
      <v-row justify="center" style="height: 90%">
        <v-col cols="11">
          <v-card class="pa-6 my-4" width="100%" raised height="100%">
            <div class="d-flex align-center">
              <v-icon color="primary" @click="onClickPrevBtn()"
                >arrow_back</v-icon
              >
              <div class="pl-2 text-h5 primary--text font-weight-medium">
                {{ templateName ? templateName : "Compliance Questionnaire" }}
              </div>
            </div>
            <v-card
              v-if="!formLoading"
              color="grey lighten-3"
              class="mt-10 pa-5"
            >
              <form
                id="fb-render"
                ref="fbRender"
                class="renderTemplate form-horizontal form-validation"
                cf-context
              ></form>
            </v-card>
            <v-bottom-sheet
              v-model="openBottomSheet"
              hide-overlay
              persistent
              no-click-animation
              width="100%"
              :retain-focus="false"
            >
              <v-sheet>
                <div class="pt-3 pa-1 d-flex justify-end">
                  <v-btn
                    color="primary"
                    large
                    style="border-radius: 10px"
                    :loading="formLoading"
                    :disabled="formLoading"
                    @click="submitForm()"
                    >Save & Continue</v-btn
                  >
                  <v-btn
                    v-if="!dynamicFormRequired"
                    class="ml-2"
                    color="primary"
                    text
                    elevation="4"
                    style="border-radius: 10px"
                    large
                    @click="$emit('move-to-next-section')"
                    >Skip</v-btn
                  >
                </div>
              </v-sheet>
            </v-bottom-sheet>
            <v-overlay absolute :value="formLoading" color="#fff" z-index="1">
              <v-progress-circular
                color="primary"
                indeterminate
                size="50"
              ></v-progress-circular>
            </v-overlay>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import "formBuilder/dist/form-render.min.js";
import "formBuilder";
import "jquery-validation/dist/jquery.validate.min.js";

import { ADD_UPDATE_VENDOR_DYNAMIC_FORM } from "@/graphql/vendorOnboardQueries";

export default {
  name: "FormRender",

  props: {
    templateId: {
      type: Number,
      required: true,
    },
    formData: {
      type: Array,
      required: true,
    },
    responseId: {
      type: Number,
      required: true,
    },
    templateName: {
      type: String,
      default: "",
    },
    dynamicFormRequired: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    formLoading: false,
    openBottomSheet: true,
  }),

  mounted() {
    let formData = this.formData;
    $("#fb-render").formRender({ formData });
  },

  methods: {
    onClickPrevBtn() {
      this.$emit("back-to-prev");
    },
    submitForm() {
      if ($("#fb-render").valid()) {
        this.updateVendorDynamicForm();
      } else {
        let snackbarData = {
          isOpen: true,
          type: "warning",
          message: "Please provide a valid response",
        };
        this.showAlert(snackbarData);
      }
    },
    updateVendorDynamicForm() {
      let vm = this;
      vm.formLoading = true;
      vm.$apollo
        .mutate({
          mutation: ADD_UPDATE_VENDOR_DYNAMIC_FORM,
          variables: {
            response: JSON.stringify($("#fb-render").formRender("userData")),
            status: "Submitted",
            checkAccess: 0,
            responseId: vm.responseId,
            vendorId: parseInt(localStorage.getItem("vendorId")),
          },
          client: "apolloClientC",
        })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.addUpdateVendorDynamicForm
          ) {
            vm.formLoading = false;
            vm.$emit("move-to-next-section");
          } else {
            vm.formLoading = false;
            throw false;
          }
        })
        .catch((err) => {
          vm.formLoading = false;
          let snackbarData = {
            isOpen: true,
            message:
              err.graphQLErrors.length !== 0
                ? err.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
        });
    },
    // show success/error message in snackbar
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>

<style>
@import url("../../assets/css/dynamic-form-builder.css");
.error {
  color: black;
}
h1,
h2,
p {
  margin: 15px 0px;
}
.formbuilder-radio-group-label {
  margin: 15px 0px;
}
.radio-group,
.checkbox-group {
  margin: 10px 0px;
}
.formbuilder-checkbox {
  margin: 5px 0px;
}
.rendered-form .form-group {
  margin-bottom: 25px !important;
}
</style>
