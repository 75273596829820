<template>
  <div class="full-height">
    <v-row justify="center" style="height: 90%">
      <v-col cols="11">
        <v-row class="full-height my-6" style="height: 100%">
          <v-col v-if="windowWidth > 960" cols="3">
            <v-card class="pa-4 text-center" raised height="100%" width="100%">
              <v-card
                v-for="(detail, index) in basicDetails"
                :key="detail.type"
                class="d-flex my-3 pa-3"
                min-height="50"
                :color="index === selectedInfo ? 'primary' : ''"
                @click="onClickNextSection(detail.value)"
              >
                <v-icon :color="index === selectedInfo ? 'white' : 'primary'">{{
                  detail.icon
                }}</v-icon>
                <div
                  class="pl-2"
                  :class="
                    index === selectedInfo ? 'white--text' : 'primary--text'
                  "
                >
                  {{ detail.heading }}
                </div>
              </v-card>
            </v-card>
          </v-col>
          <v-col cols="12" lg="9" md="9" sm="12" xl="9">
            <v-card
              raised
              height="100%"
              width="100%"
              class="pa-3"
              min-height="400"
            >
              <div>
                <div class="d-flex">
                  <v-icon color="primary" @click="onClickPrevBtn()"
                    >arrow_back</v-icon
                  >
                  <div class="pl-2 text-h5 primary--text font-weight-medium">
                    {{ stepHeading() }}
                  </div>
                </div>
                <Education
                  ref="education"
                  v-if="selectedInfo === 0"
                  :show-skip="showSkipOption"
                  @education-saved="onSaveAndMoveTONextSec(1)"
                  @education-done="showSkipOption = true"
                ></Education>
                <Certificate
                  ref="certificate"
                  v-else-if="selectedInfo === 1"
                  :show-skip="showSkipOption"
                  @certificate-saved="onSaveAndMoveTONextSec(2)"
                  @certificate-done="showSkipOption = true"
                ></Certificate>
                <Training
                  ref="training"
                  v-else-if="selectedInfo === 2"
                  :show-skip="showSkipOption"
                  @training-saved="$emit('move-to-next-session')"
                  @training-done="showSkipOption = true"
                ></Training>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Education from "./Education.vue";
import Certificate from "./Certificate.vue";
import Training from "./Training.vue";
import { GET_FORM_FIELDS_AND_TYPE } from "@/graphql/OnboardingQueries";

export default {
  name: "Certification",

  components: { Education, Certificate, Training },

  data: () => ({
    selectedInfo: 0,
    showSkipOption: false,
    formList: [],
  }),

  computed: {
    // current window size
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    // to check the device is mobile based on window size
    isMobileView() {
      return this.$store.state.isMobileWindowSize;
    },
    basicDetails() {
      let tabs = [
        {
          heading: "Education",
          type: "basic",
          icon: "notes",
          value: 0,
        },
      ];
      if (this.getFieldAlias(243).Field_Visiblity == "Yes") {
        tabs.push({
          heading: "Certification",
          type: "license",
          icon: "fa-solid fa-id-card",
          value: 1,
        });
      }
      if (this.getFieldAlias(244).Field_Visiblity == "Yes") {
        tabs.push({
          heading: "Training",
          type: "address",
          icon: "pin_drop",
          value: 2,
        });
      }
      return tabs;
    },
  },
  mounted() {
    this.getFieldProps();
  },

  methods: {
    stepHeading() {
      if (this.selectedInfo === 0) {
        return "Education";
      } else if (this.selectedInfo === 1) {
        return "Certification";
      } else {
        return "Training";
      }
    },
    onClickNextSection(nextStep) {
      this.selectedInfo = nextStep;
    },
    onClickPrevBtn() {
      if (this.selectedInfo === 0) {
        this.$emit("back-to-prev");
      } else this.selectedInfo -= 1;
    },
    onSaveAndMoveTONextSec(nextStep) {
      this.showSkipOption = true;
      if (
        this.getFieldAlias(243).Field_Visiblity == "Yes" &&
        this.getFieldAlias(244).Field_Visiblity == "Yes"
      ) {
        this.selectedInfo = nextStep;
      } else {
        this.$emit("move-to-next-session");
      }
    },
    getFieldAlias(fieldId) {
      return this.formList.find((field) => field.Field_Id === fieldId) || {};
    },
    getFieldProps() {
      let vm = this;
      vm.saveLoading = true;
      this.$apollo
        .query({
          query: GET_FORM_FIELDS_AND_TYPE,
          variables: {
            form_Id: 178,
          },
          client: "apolloClientB",
        })
        .then((res) => {
          if (res.data.getFormFeildsByFormIdAndTab) {
            this.formList = res.data.getFormFeildsByFormIdAndTab.formFields;
            this.isEducationMandatory = this.getFieldAlias(129).Mandatory_Field;
          }
          vm.saveLoading = false;
        })
        .catch(() => {
          vm.saveLoading = false;
        });
    },
  },
};
</script>
