<template>
  <div v-if="isMounted" class="full-height">
    <v-row justify="center" style="height: 90%">
      <v-col cols="11">
        <v-row class="full-height my-6" style="height: 100%">
          <v-col v-if="windowWidth > 960" cols="3">
            <v-card class="pa-4 text-center" raised height="100%" width="100%">
              <v-card
                v-for="(detail, index) in basicDetails"
                :key="detail.type"
                class="d-flex my-3 pa-3"
                min-height="50"
                :color="index === selectedInfo ? 'primary' : ''"
                @click="onSaveBasicInfo(detail)"
              >
                <v-icon :color="index === selectedInfo ? 'white' : 'primary'">{{
                  detail.icon
                }}</v-icon>
                <div
                  class="pl-2"
                  :class="
                    index === selectedInfo ? 'white--text' : 'primary--text'
                  "
                >
                  {{ detail.heading }}
                </div>
              </v-card>
            </v-card>
          </v-col>
          <v-col cols="12" lg="9" md="9" sm="12" xl="9">
            <v-card raised height="100%" width="100%" class="pa-3">
              <div>
                <div class="d-flex">
                  <v-icon
                    v-if="selectedInfo >= 0"
                    color="primary"
                    @click="onClickPrevBtn()"
                    >arrow_back</v-icon
                  >
                  <div class="pl-2 text-h5 primary--text font-weight-medium">
                    {{ stepHeading() }}
                  </div>
                </div>
                <LicenseInfo
                  ref="licenseInfo"
                  v-if="selectedInfo === 0 && !saveLoading"
                  :personalInfo="personalInfoDetails"
                  :formList="formList"
                  @form-dirty="isFormDirty = true"
                ></LicenseInfo>
                <PassportDetails
                  ref="passportInfo"
                  v-else-if="selectedInfo === 1 && !saveLoading"
                  :personal-info="personalInfoDetails"
                  :formList="formList"
                  @form-dirty="isFormDirty = true"
                ></PassportDetails>
                <AccreditationDetails
                  ref="accreditationInfo"
                  v-else-if="selectedInfo === 2"
                  :personal-info="personalInfoDetails"
                  @form-dirty="isFormDirty = true"
                ></AccreditationDetails>
                <v-divider></v-divider>
                <div class="mt-3 d-flex justify-end">
                  <v-btn
                    :disabled="!isFormDirty"
                    color="primary"
                    style="border-radius: 10px"
                    large
                    :loading="saveLoading"
                    @click="onSaveBasicInfo()"
                    >Save & Continue</v-btn
                  >
                  <v-btn
                    v-if="showSkipButton"
                    class="ml-2"
                    elevation="2"
                    text
                    color="primary"
                    style="border-radius: 10px"
                    large
                    @click="onSkipAccreditation()"
                    >Skip</v-btn
                  >
                </div>
              </div>
              <v-overlay absolute :value="saveLoading" color="#fff" z-index="1">
                <v-progress-circular
                  color="primary"
                  indeterminate
                  size="50"
                ></v-progress-circular>
              </v-overlay>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  GET_ACCREDITATIONS,
  ADD_ACCREDITATIONS,
  UPDATE_ACCREDITATIONS,
  LIST_ACCREDITATIONS_AND_TYPES,
  DELETE_ACCREDITATIONS,
  GET_FORM_FIELDS_AND_TYPE,
  GET_PERSONAL_INFO,
  UPDATE_PERSONAL_INFO,
  ADD_DOCUMENT,
  GET_ALL_DOCUMENTS,
  UPDATE_DOC_DOCUMENT,
} from "@/graphql/OnboardingQueries";
import moment from "moment";
import LicenseInfo from "./LicenseInformation.vue";
import PassportDetails from "./PassportDetails.vue";
import AccreditationDetails from "./AccreditationsDetails.vue";

export default {
  name: "SkillProfiles",

  components: { LicenseInfo, PassportDetails, AccreditationDetails },

  props: {
    personalDetails: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    addAccreditations: [],
    accreditationsList: [],
    accreditationsTypeList: [],
    receivedDateModal: false,
    expiryDateModal: false,
    maxYear: "",
    minJoinDate: "",
    saveLoading: false,
    apiCallCount: 0,
    accreditationLoading: true,
    showSkip: false,
    isFileLoading: false,
    selectedInfo: 0,
    showSkipOption: false,
    editedPassportDetails: {},
    editedLicenseDetails: {},
    isLicenseMandatory: "No",
    isPassportMandatory: "No",
    formList: [],
    personalInfoDetails: {},
    isMounted: false,
    documentList: [],
    isFormDirty: false,
  }),

  created() {
    let storedDob = localStorage.getItem("DOB");
    if (storedDob && storedDob !== "null") {
      this.minJoinDate = moment(storedDob).format("YYYY-MM-DD");
    }
    this.maxYear = moment().format("YYYY-MM-DD");
  },

  apollo: {
    retrieveAccreditationAndType: {
      query: LIST_ACCREDITATIONS_AND_TYPES,
      client: "apolloClientB",
      variables() {
        return {
          urlHash: localStorage.getItem("urlHash"),
        };
      },
      result({ data }) {
        if (data && data.retrieveAccreditationAndType) {
          this.accreditationsTypeList =
            data.retrieveAccreditationAndType.accreditationAndType;
        }
      },
    },
    retrieveAccreditationDetails: {
      query: GET_ACCREDITATIONS,
      client: "apolloClientB",
      fetchPolicy: "no-cache",
      variables() {
        return {
          urlHash: localStorage.getItem("urlHash"),
        };
      },
      result({ data }) {
        if (data && data.retrieveAccreditationDetails) {
          const { accreditationDetails } = data.retrieveAccreditationDetails;
          if (accreditationDetails && accreditationDetails.length > 0) {
            let accreditationsList = [];
            for (let accreditation of accreditationDetails) {
              let accreditationsOnj = {
                accreditationAndType:
                  accreditation.accreditationCategoryAndTypeId,
                identifier: accreditation.identifier,
                receivedDate: accreditation.receivedDate,
                expiryDate: accreditation.expiryDate,
                accreditationsId: accreditation.accreditationDetailId,
                fileName: accreditation.fileName
                  ? accreditation.fileName.split("?")[3]
                  : "",
                fileNameWithoutFormat: accreditation.fileName,
                fileValidationError: "",
                examRating:
                  accreditation.examRating || accreditation.examRating === 0
                    ? parseInt(accreditation.examRating)
                    : null,
                examDateYear: accreditation.examDateYear
                  ? parseInt(accreditation.examDateYear)
                  : null,
                examDateMonth: accreditation.examDateMonth,
              };
              accreditationsList.push(accreditationsOnj);
            }
            this.addAccreditations = accreditationsList;
            this.showSkip = true;
          } else {
            this.addAccreditations.push({
              accreditationAndType: 0,
              identifier: "",
              receivedDate: "",
              expiryDate: "",
              fileName: "",
              fileNameWithoutFormat: "",
              accreditationsId: 0,
              fileValidationError: "",
              examDateMonth: "",
              examDateYear: null,
              examRating: null,
            });
          }
          this.showSkip = true;
        }
        this.accreditationLoading = false;
      },
      // Error handling
      error() {
        this.accreditationLoading = false;
      },
    },
  },

  computed: {
    basicDetails() {
      return [
        {
          heading: "Driving License Details",
          type: "license",
          icon: "fa-solid fa-id-card",
          value: 0,
        },
        {
          heading: "Passport Details",
          type: "passport",
          icon: "article",
          value: 1,
        },
        {
          heading: this.getFieldAlias(230).Field_Alias,
          type: "accreditations",
          icon: "article",
          value: 2,
        },
      ];
    },
    formatDate() {
      return (date) => {
        return date ? moment(date).format("DD/MM/YYYY") : "";
      };
    },
    isIndia() {
      let country = localStorage.getItem("country");
      if (country && country.toLowerCase() === "in") {
        return true;
      }
      return false;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    showSkipButton() {
      if (
        (this.selectedInfo == 0 && this.isLicenseMandatory == "No") ||
        (this.selectedInfo == 1 && this.isPassportMandatory == "No") ||
        (this.selectedInfo === 2 && this.showSkip)
      ) {
        return true;
      }
      return false;
    },
  },

  mounted() {
    if (this.isIndia) {
      this.showSkip = true;
    }
    this.getPersonalInfo(0);
    this.isMounted = true;
    this.getFieldProps();
    this.getAllDocuments();
  },

  watch: {
    selectedInfo(val) {
      if (val == 1 || val == 0) {
        this.getPersonalInfo(val);
      }
    },
  },

  methods: {
    getFieldAlias(fieldId) {
      return this.formList.find((field) => field.Field_Id === fieldId) || {};
    },
    async onSaveBasicInfo(info) {
      this.editedLicenseDetails = {};
      this.editedPassportDetails = {};
      let isFormValid = await this.validateForms();
      if (isFormValid) {
        this.isFormDirty = false;
        this.selectedInfo = info ? info.value : this.selectedInfo + 1;
        if (this.selectedInfo > 2) {
          this.onSaveAccreditations();
        } else {
          this.submitPersonalDetails(this.selectedInfo);
        }
      }
    },
    async validateForms() {
      return new Promise((resolve) => {
        switch (this.selectedInfo) {
          case 0:
            this.$refs.licenseInfo
              .validateLicenseInfo()
              .then((validationResponse) => {
                if (validationResponse) {
                  this.editedLicenseDetails =
                    this.$refs.licenseInfo.licenseInfoDetails();
                  let document = {
                    fileNameWithoutFormat:
                      this.editedLicenseDetails.License_File_Name,
                    Document_Name: "Driving License",
                    Category_Id: 1,
                    Document_Type_Id: 1,
                    Document_Sub_Type_Id: 3,
                  };
                  this.addDocumentList(document);
                }
                resolve(validationResponse);
              });
            break;
          case 1:
            this.$refs.passportInfo
              .validatePassportInfo()
              .then((validationResponse) => {
                if (validationResponse) {
                  this.editedPassportDetails =
                    this.$refs.passportInfo.passportInfoDetails();
                  let document = {
                    fileNameWithoutFormat:
                      this.editedPassportDetails.Passport_File_Name,
                    Document_Name: "Passport",
                    Category_Id: 1,
                    Document_Type_Id: 1,
                    Document_Sub_Type_Id: 2,
                  };
                  this.addDocumentList(document);
                }
                resolve(validationResponse);
              });
            break;
          case 2:
            this.$refs.accreditationInfo
              .validateAccreditations()
              .then((validationResponse) => {
                this.addAccreditations =
                  this.$refs.accreditationInfo.accreditationInfoDetails();
                resolve(validationResponse);
              });
            break;
          default:
            resolve(true);
            break;
        }
      });
    },
    submitPersonalDetails(nextStep) {
      let vm = this;
      vm.saveLoading = true;
      let submitObj = {
        Org_Code: localStorage.getItem("orgCode"),
        Url_Hash: localStorage.getItem("urlHash"),
        ...vm.stepDetails(),
        ...vm.passportDetails(),
        ...vm.licenseDetails(),
      };
      vm.$apollo
        .mutate({
          mutation: UPDATE_PERSONAL_INFO,
          variables: submitObj,
          client: "apolloClientA",
        })
        .then(() => {
          vm.getPersonalInfo(nextStep);
        })
        .catch((updateErr) => {
          vm.saveLoading = false;
          let snackbarData = {
            isOpen: true,
            message:
              updateErr.graphQLErrors.length !== 0
                ? updateErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
        });
    },
    stepHeading() {
      if (this.selectedInfo === 0) {
        return "Driving License Details";
      } else if (this.selectedInfo === 1) {
        return "Passport Details";
      } else if (this.selectedInfo === 2) {
        return this.getFieldAlias(230).Field_Alias;
      } else {
        this.$emit("accreditations-saved");
      }
    },
    updateFileName(fileName, index) {
      this.addAccreditations[index]["fileNameWithoutFormat"] = fileName;
      this.addAccreditations[index]["fileValidationError"] = "";
    },

    onClickPrevBtn() {
      this.isFormDirty = false;
      if (this.selectedInfo === 0) {
        this.$emit("back-to-prev");
      } else {
        this.selectedInfo -= 1;
      }
    },

    addMoreAccreditations() {
      this.validateAccreditations().then((isValid) => {
        if (isValid) {
          this.addAccreditations.push({
            accreditationAndType: 0,
            identifier: "",
            receivedDate: "",
            expiryDate: "",
            fileName: "",
            fileNameWithoutFormat: "",
            fileValidationError: "",
            accreditationsId: 0,
          });
        }
      });
    },

    emptyExpiryDate(index) {
      this.addAccreditations[index]["expiryDate"] = "";
      this.receivedDateModal = false;
    },

    validateAccreditations() {
      return new Promise((resolve) => {
        let callCount = 0,
          isValidCount = 0;
        for (let aIndex in this.addAccreditations) {
          this.$refs["accreditationsForm" + aIndex][0]
            .validate()
            .then((validationResponse) => {
              callCount += 1;
              let aIndexFileName =
                this.addAccreditations[aIndex]["fileNameWithoutFormat"];
              if (validationResponse && aIndexFileName) {
                isValidCount += 1;
                if (callCount === this.addAccreditations.length) {
                  resolve(isValidCount === this.addAccreditations.length);
                }
              } else if (!aIndexFileName) {
                this.addAccreditations[aIndex]["fileValidationError"] =
                  "Document is required";
                resolve(false);
              }
            });
        }
      });
    },
    getAllDocuments() {
      let vm = this;
      this.isFileLoading = true;
      this.documentLoading = true;
      vm.$apollo
        .query({
          query: GET_ALL_DOCUMENTS,
          client: "apolloClientA",
          fetchPolicy: "no-cache",
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
          },
        })
        .then(async (data) => {
          data = data.data;
          if (data && data.getAllDocuments) {
            const { documents } = data.getAllDocuments;
            if (documents && documents.length > 0) {
              this.documentList = documents;
            }
            this.documentLoading = false;
            this.saveLoading = false;
          }
        })
        .catch(() => {
          this.documentLoading = false;
          this.saveLoading = false;
        });
    },

    addDocumentList(documentItem) {
      if (this.documentList && this.documentList.length > 0) {
        let calledFunction = false;
        for (let doc of this.documentList) {
          if (doc.Document_Sub_Type_Id == documentItem.Document_Sub_Type_Id) {
            this.updateDocumentDetails(documentItem, doc.Document_Id);
            calledFunction = true;
            break;
          }
        }
        if (!calledFunction) {
          this.addDocumentDetails(documentItem);
        }
      } else {
        this.addDocumentDetails(documentItem);
      }
    },

    addDocumentDetails(documentItem) {
      let vm = this;
      vm.saveLoading = true;
      vm.$apollo
        .mutate({
          mutation: ADD_DOCUMENT,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            File_Name: documentItem.fileNameWithoutFormat,
            Document_Name: documentItem.Document_Name,
            Category_Id: documentItem.Category_Id,
            Document_Type_Id: documentItem.Document_Type_Id,
            Sub_Document_Type_Id: documentItem.Document_Sub_Type_Id,
          },
          client: "apolloClientA",
        })
        .then(() => {
          this.saveLoading = false;
        })
        .catch(() => {
          this.saveLoading = false;
        });
    },
    updateDocumentDetails(documentItem, documentId) {
      let vm = this;
      vm.saveLoading = true;
      vm.$apollo
        .mutate({
          mutation: UPDATE_DOC_DOCUMENT,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
            File_Name: documentItem.fileNameWithoutFormat,
            Document_Id: documentId,
            Document_Name: documentItem.Document_Name,
            Category_Id: documentItem.Category_Id,
            Document_Type_Id: documentItem.Document_Type_Id,
            Sub_Document_Type_Id: documentItem.Document_Sub_Type_Id,
          },
          client: "apolloClientA",
        })
        .then(() => {
          vm.saveLoading = false;
        })
        .catch(() => {
          vm.saveLoading = false;
        });
    },

    onSaveAccreditations() {
      this.apiCallCount = 0;
      for (let accreditation of this.addAccreditations) {
        this.apiCallCount += 1;
        if (accreditation.accreditationsId) {
          this.updateAccreditationsDetails(accreditation);
        } else {
          this.addAccreditationsDetails(accreditation);
        }
      }
    },

    onSkipAccreditation() {
      if (this.selectedInfo === 2) {
        this.$emit("accreditations-saved");
      } else {
        this.selectedInfo += 1;
      }
    },

    addAccreditationsDetails(accreditationsItem) {
      let vm = this;
      vm.saveLoading = true;
      vm.$apollo
        .mutate({
          mutation: ADD_ACCREDITATIONS,
          variables: {
            urlHash: localStorage.getItem("urlHash"),
            accreditationCategoryAndTypeId:
              accreditationsItem.accreditationAndType
                ? accreditationsItem.accreditationAndType
                : accreditationsItem.accreditationCategoryAndTypeId,
            identifier: accreditationsItem.identifier,
            receivedDate:
              accreditationsItem.receivedDate &&
              accreditationsItem.receivedDate != "Invalid date"
                ? accreditationsItem.receivedDate
                : null,
            expiryDate:
              accreditationsItem.expiryDate &&
              accreditationsItem.expiryDate != "Invalid date"
                ? accreditationsItem.expiryDate
                : null,
            fileName: accreditationsItem.fileNameWithoutFormat,
            examRating: accreditationsItem.examRating
              ? parseInt(accreditationsItem.examRating)
              : null,
            examDateYear: accreditationsItem.examDateYear
              ? parseInt(accreditationsItem.examDateYear)
              : null,
            examDateMonth: accreditationsItem.examDateMonth,
          },
          client: "apolloClientC",
        })
        .then(() => {
          if (vm.apiCallCount === vm.addAccreditations.length) {
            vm.$emit("accreditations-saved");
          }
        })
        .catch((addErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
          if (vm.apiCallCount === vm.addAccreditations.length) {
            vm.saveLoading = false;
          }
        });
    },

    updateAccreditationsDetails(accreditationsItem) {
      let vm = this;
      vm.saveLoading = true;
      vm.$apollo
        .mutate({
          mutation: UPDATE_ACCREDITATIONS,
          variables: {
            urlHash: localStorage.getItem("urlHash"),
            accreditationCategoryAndTypeId:
              accreditationsItem.accreditationAndType,
            identifier: accreditationsItem.identifier,
            receivedDate:
              accreditationsItem.receivedDate &&
              accreditationsItem.receivedDate != "Invalid date"
                ? accreditationsItem.receivedDate
                : null,
            expiryDate:
              accreditationsItem.expiryDate &&
              accreditationsItem.expiryDate != "Invalid date"
                ? accreditationsItem.expiryDate
                : null,
            fileName: accreditationsItem.fileNameWithoutFormat,
            accreditationDetailId: accreditationsItem.accreditationsId,
            examRating: accreditationsItem.examRating
              ? parseInt(accreditationsItem.examRating)
              : null,
            examDateYear: accreditationsItem.examDateYear
              ? parseInt(accreditationsItem.examDateYear)
              : null,
            examDateMonth: accreditationsItem.examDateMonth,
          },
          client: "apolloClientC",
        })
        .then(() => {
          if (vm.apiCallCount === vm.addAccreditations.length) {
            vm.$emit("accreditations-saved");
          }
        })
        .catch((addErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
          if (vm.apiCallCount === vm.addAccreditations.length) {
            vm.saveLoading = false;
          }
        });
    },

    removeAccreditations(index, accreditationsItem) {
      if (accreditationsItem.accreditationsId) {
        let vm = this;
        this.$apollo
          .mutate({
            mutation: DELETE_ACCREDITATIONS,
            variables: {
              urlHash: localStorage.getItem("urlHash"),
              accreditationDetailId: accreditationsItem.accreditationsId,
            },
            client: "apolloClientC",
          })
          .then(() => {
            let snackbarData = {
              isOpen: true,
              message: "Deleted Successfully",
              type: "success",
            };
            vm.showAlert(snackbarData);
            vm.addAccreditations.splice(index, 1);
            if (index === 0 && vm.addAccreditations.length === 0) {
              vm.addAccreditations.push({
                accreditationAndType: "",
                identifier: "",
                receivedDate: "",
                expiryDate: "",
                fileName: "",
                fileNameWithoutFormat: "",
                fileValidationError: "",
                accreditationsId: 0,
              });
              if (vm.isIndia) {
                vm.showSkip = true;
              }
            }
          })
          .catch((deleteErr) => {
            let snackbarData = {
              isOpen: true,
              message:
                deleteErr.graphQLErrors.length !== 0
                  ? deleteErr.graphQLErrors[0].message
                  : "Something went wrong. It could be a possibility due to network connectivity error..",
              type: "warning",
            };
            vm.showAlert(snackbarData);
          });
      } else {
        this.addAccreditations.splice(index, 1);
      }
    },

    getFieldProps() {
      let vm = this;
      vm.saveLoading = true;
      this.$apollo
        .query({
          query: GET_FORM_FIELDS_AND_TYPE,
          variables: {
            form_Id: 178,
          },
          client: "apolloClientB",
        })
        .then((res) => {
          if (res.data.getFormFeildsByFormIdAndTab) {
            this.formList = res.data.getFormFeildsByFormIdAndTab.formFields;
            this.isLicenseMandatory = this.getFieldAlias(116).Mandatory_Field;
            this.isPassportMandatory = this.getFieldAlias(123).Mandatory_Field;
          }
          vm.saveLoading = false;
        })
        .catch(() => {
          vm.saveLoading = false;
        });
    },

    getPersonalInfo(nextStep) {
      let vm = this;
      vm.saveLoading = true;
      this.$apollo
        .query({
          query: GET_PERSONAL_INFO,
          variables: {
            Org_Code: localStorage.getItem("orgCode"),
            Url_Hash: localStorage.getItem("urlHash"),
          },
          client: "apolloClientA",
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          if (res.data.getPersonalInfo) {
            this.personalInfoDetails = res.data.getPersonalInfo;
          }
          vm.saveLoading = false;
          vm.selectedInfo = nextStep;
        })
        .catch((addErr) => {
          let snackbarData = {
            isOpen: true,
            message:
              addErr.graphQLErrors.length !== 0
                ? addErr.graphQLErrors[0].message
                : "Something went wrong. It could be a possibility due to network connectivity error..",
            type: "warning",
          };
          vm.showAlert(snackbarData);
          vm.saveLoading = false;
        });
    },

    stepDetails() {
      return {
        Salutation: this.personalInfoDetails.Salutation,
        Emp_First_Name: this.personalInfoDetails.Emp_First_Name,
        Emp_Last_Name: this.personalInfoDetails.Emp_Last_Name,
        Emp_Middle_Name: this.personalInfoDetails.Emp_Middle_Name,
        Emp_Pref_First_Name: this.personalInfoDetails.Emp_Pref_First_Name,
        DOB:
          this.personalInfoDetails.DOB &&
          this.personalInfoDetails.DOB != "Invalid date"
            ? this.personalInfoDetails.DOB
            : null,
        Place_Of_Birth: this.personalInfoDetails.Place_Of_Birth,
        Gender_Id: this.personalInfoDetails.Gender_Id,
        Gender_Expression_Id: this.personalInfoDetails?.Gender_Expression_Id,
        Gender_Identity_Id: this.personalInfoDetails?.Gender_Identity_Id,
        Gender_Identity: this.personalInfoDetails?.Gender_Identity,
        Gender_Expression: this.personalInfoDetails?.Gender_Expression,
        Marital_Status: this.personalInfoDetails.Marital_Status,
        Blood_Group: this.personalInfoDetails.Blood_Group,
        Lang_Known: this.personalInfoDetails.Lang_Known.map((lang) => {
          const { Lang_Known, Lang_Spoken, Lang_Read_Write, Lang_Proficiency } =
            lang;
          return {
            Lang_Known,
            Lang_Spoken,
            Lang_Read_Write,
            Lang_Proficiency,
          };
        }),
        Nationality_Id: this.personalInfoDetails.Nationality_Id,
        Nationality: this.personalInfoDetails.Nationality,
        Personal_Email: this.personalInfoDetails.Personal_Email,
        Ethnic_Race: this.personalInfoDetails.Ethnic_Race,
        hobbies: this.personalInfoDetails.hobbies,
        Religion_Id: this.personalInfoDetails.Religion_Id,
        Religion: this.personalInfoDetails.Religion,
        Appellation: this.personalInfoDetails.Appellation,
        Caste: this.personalInfoDetails.Caste,
        Is_Manager: this.personalInfoDetails.Is_Manager,
        Physically_Challenged: this.personalInfoDetails.Physically_Challenged,
        Smoker: this.personalInfoDetails.Smoker,
        Smokerasof:
          this.personalInfoDetails.Smokerasof &&
          this.personalInfoDetails.Smokerasof != "Invalid date"
            ? this.personalInfoDetails.Smokerasof
            : null,
        PAN: this.personalInfoDetails.PAN,
        Aadhaar_Card_Number: this.personalInfoDetails.Aadhaar_Card_Number,
        Military_Service: this.personalInfoDetails.Military_Service,
        Photo_Path: this.personalInfoDetails.Photo_Path,
        pApartment_Name: this.personalInfoDetails.pApartment_Name,
        pStreet_Name: this.personalInfoDetails.pStreet_Name,
        pBarangay: this.personalInfoDetails.pBarangay,
        pRegion: this.personalInfoDetails.pRegion,
        pCity: this.personalInfoDetails.pCity,
        pState: this.personalInfoDetails.pState,
        pCountry: this.personalInfoDetails.pCountry,
        pPincode: this.personalInfoDetails.pPincode,
        cApartment_Name: this.personalInfoDetails.cApartment_Name,
        cStreet_Name: this.personalInfoDetails.cStreet_Name,
        cCity: this.personalInfoDetails.cCity,
        cBarangay: this.personalInfoDetails.cBarangay,
        cRegion: this.personalInfoDetails.cRegion,
        cState: this.personalInfoDetails.cState,
        cCountry: this.personalInfoDetails.cCountry,
        cPincode: this.personalInfoDetails.cPincode,
        Use_Location_Address: this.personalInfoDetails.Use_Location_Address,
        Land_Line_No: this.personalInfoDetails.Land_Line_No,
        Mobile_No: this.personalInfoDetails.Mobile_No
          ? this.personalInfoDetails.Mobile_No.replace(/^0| /g, "")
          : this.personalInfoDetails.Mobile_No,
        Mobile_No_Country_Code: this.personalInfoDetails.Mobile_No_Country_Code,
        Emergency_Contact_No: this.personalInfoDetails.Fax_No,
        Emergency_Contact_Name: this.personalInfoDetails.Emergency_Contact_Name,
        Emergency_Contact_Relation:
          this.personalInfoDetails.Emergency_Contact_Relation,
        Gender_Orientations: this.personalInfoDetails.Gender_Orientations,
        Pronoun: this.personalInfoDetails.Pronoun,
        Gender: this.personalInfoDetails.Gender,
        Statutory_Insurance_Number:
          this.personalInfoDetails.Statutory_Insurance_Number,
        PRAN_No: this.personalInfoDetails.PRAN_No,
        UAN: this.personalInfoDetails.UAN,
        Tax_Code: this.personalInfoDetails.Tax_Code,
      };
    },
    passportDetails() {
      if (
        this.editedPassportDetails &&
        Object.keys(this.editedPassportDetails).length > 0
      ) {
        return this.editedPassportDetails;
      } else {
        return {
          Passport_No: this.personalInfoDetails.Passport_No,
          Passport_File_Name: this.personalInfoDetails.Passport_File_Name,
          Issue_Date: this.personalInfoDetails.Issue_Date,
          Passport_Issuing_Country:
            this.personalInfoDetails.Passport_Issuing_Country,
          Passport_Issuing_Authority:
            this.personalInfoDetails.Passport_Issuing_Authority,
          Expiry_Date:
            this.personalInfoDetails.Expiry_Date &&
            this.personalInfoDetails.Expiry_Date != "Invalid date"
              ? this.personalInfoDetails.Expiry_Date
              : null,
        };
      }
    },
    licenseDetails() {
      if (
        this.editedLicenseDetails &&
        Object.keys(this.editedLicenseDetails).length > 0
      ) {
        return this.editedLicenseDetails;
      } else {
        return {
          Driving_License_No: this.personalInfoDetails.Driving_License_No,
          License_Issue_Date:
            this.personalInfoDetails.License_Issue_Date &&
            this.personalInfoDetails.License_Issue_Date != "Invalid date"
              ? this.personalInfoDetails.License_Issue_Date
              : null,
          License_Expiry_Date:
            this.personalInfoDetails.License_Expiry_Date &&
            this.personalInfoDetails.License_Expiry_Date != "Invalid date"
              ? this.personalInfoDetails.License_Expiry_Date
              : null,
          Issuing_Authority: this.personalInfoDetails.Issuing_Authority,
          Issuing_Country: this.personalInfoDetails.Issuing_Country,
          Vehicle_Type: this.personalInfoDetails.Vehicle_Type,
          Issuing_State: this.personalInfoDetails.Issuing_State,
          License_File_Name: this.personalInfoDetails.License_File_Name,
        };
      }
    },
    // show error or success message
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>
